.menu-wrapper {
  a {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-left: 20px;

    .menu-icon {
      background: #f7f7f7;
      padding: 10px;
      border-radius: 8px;

      &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      .anticon.anticon-setting {
        color: black;
      }
    }

    &.active {
      .menu-icon {
        background: #222323;

        .anticon.anticon-setting {
          color: #f7f7f7;
        }

        path,
        rect {
          stroke: #f7f7f7 !important;
        }
      }
    }
  }
}

.p-5 {
  padding: 5px;
}

.login-layout {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/loginV2/bg-login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.anticon.anticon-bars svg {
  color: #f7f7f7 !important;
  width: 25px !important;
  height: 25px !important;
}
