//BUTTON oneamz-yellow
.btn {
  height: 2.625rem;
  padding: 0.75rem 1.5rem;
  line-height: 0;
  border: none;

  :hover {
    background-color: inherit;
    color: inherit !important;
    border: none;
  }
}

.btn-hover-dark {
  height: 2.625rem;
}
.btn-hover-dark:hover,
.btn-hover-dark:hover:focus {
  color: inherit !important;
  border: none !important;
  box-shadow: none !important;
}

.btn-hover-dark:hover .ant-btn,
.btn-hover-dark:hover .ant-btn:focus {
  color: inherit !important;
}

.btn-hover-white {
  height: 2.625rem;
}
.btn-hover-white:hover,
.btn-hover-white:hover:focus {
  color: $cloud !important;
  border: none !important;
  box-shadow: none !important;
}

.btn-hover-white:hover .ant-btn,
.btn-hover-white:hover .ant-btn:focus {
  color: inherit !important;
}

.btn-sm {
  border: none;
  height: 1.625rem;
  padding: 5px 12px;
  line-height: 0;
}

//CHIPS / CHOICE / FILTER
.chip {
  height: 2rem;
  padding: 5px 12px;
  line-height: 0;
}

.chip-outlined {
  height: 2rem;
  padding: 6px 1rem;
  line-height: 0;
}

.btn-alert-outlined {
  border: 1px solid $error;
  height: 2.625rem;
  padding: 0.75rem 1rem;
  line-height: 0;
}

//BUTTON TERTIARY
.link-btn {
  box-shadow: none;

  span {
    text-decoration: underline;
  }
}
