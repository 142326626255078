.mt-48 {
  margin-top: 48px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-2 {
  margin-top: 2rem;
}

// .mr-24 {
//     margin-top: 8px;
// }

.pt-32 {
  padding-top: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.p-0 {
  padding: 0px;
}

.m-0 {
  margin: 0px !important;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.fs-16 {
  font-size: 16px;
}

.icon-size-20 {
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.fbm-steps {
  .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #ffcb2b;
  }

  .ant-steps-item-icon > .ant-steps-icon {
    font-size: 18px;
    color: black !important;
    font-weight: 600;
  }

  .ant-steps-item-icon {
    height: 50px;
    width: 50px;
    padding: 9px;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #ffcb2b;
    border-color: #ffcb2b;
  }
}

.step1 {
  .product-item {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    margin-bottom: 20px;

    p {
      padding-right: 20px;
      color: #797979;
    }

    a {
      b {
        color: black;
      }

      p {
        color: #797979;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }

  :where(.css-dev-only-do-not-override-xu9wm8).ant-form-item {
    margin-bottom: 0px;
  }

  .asin-finder {
    .ant-input-group-addon {
      background: none;
    }

    input,
    button {
      height: 56px;
    }

    input {
      border-left: none;
    }
  }

  p {
    padding-top: 10px;
    margin: 0px;
  }
}

.modal-service-list {
  td:not(.service-title) {
    border: none;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #e7e7e7;
  }
}
