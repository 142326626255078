.amz-radio {
  span {
    border: none !important;
  }

  span .ant-radio-inner {
    background: pure-white;
    border: 1px solid #bcbcbc;
    height: 20px;
    width: 20px;
  }

  span.ant-radio-checked {
    .ant-radio-inner {
      background: $slate-blue !important;
    }
  }
}

.amz-radio-oneamz-yellow {
  span {
    border: none !important;
  }

  span .ant-radio-inner {
    background: #70787f;
    border: 1px solid #bcbcbc;
    height: 20px;
    width: 20px;
  }

  span.ant-radio-checked {
    .ant-radio-inner {
      background: $oneamz-yellow !important;
    }
  }
}
