.profile-starter-card {
  position: relative; // Pozisyonlamayı kontrol etmek için
  overflow: hidden; // Resim Paper sınırlarını aştığında gizlemek için

  &::after {
    // Pseudo-element ile arka plan resmi ekleme
    content: ""; // Pseudo-element için içerik
    position: absolute; // Pozisyonu absolute yaparak konumlandırma
    bottom: -6rem; // Alt kısma yasla
    right: -8rem; // Sağ kısma yasla
    background-image: url("../../Dashboard/dashboard-starter.png"); // Resim yolu
    background-size: contain; // Resmin boyutunu sığdır
    background-repeat: no-repeat; // Tekrar etmemesi için
    width: 700px;
    height: 300px;
    z-index: 0;

    @media (max-width: 1198.8px) {
      width: 300px;
      height: 100px;
    }
  }

  .starter-img {
    z-index: -10;
  }
}
