.border-radius-sm {
  border-radius: $inner-radius;
}

.border-radius-md {
  border-radius: $outer-radius;
}

.bottom-border-radius-md {
  border-bottom-left-radius: $outer-radius;
  border-bottom-right-radius: $outer-radius;
}

.border-radius-lg {
  border-radius: $full-round;
}

.border-radius-xs {
  border-radius: $radius-small;
}

.border-radius-8 {
  border-radius: $radius-8;
}

.border-radius-12 {
  border-radius: $radius-12;
}

.border-radius-28 {
  border-radius: $radius-28;
}
