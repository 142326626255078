//Fonts
@import "./systems/_fonts.scss";

//Colors
@import "./systems/_colors.scss";

//Radius
@import "./systems/radius";

//Font Color
@import "./systems/_font-colors.scss";

//Background Colour
@import "./systems/_background-color.scss";

//Border Radius
@import "./systems/border-radius";

//Spacing
//Margin
@import "./systems/margin-spacing";

//Padding
@import "./systems/padding-spacing";

//Typography
@import "./systems/typograhphy";

//Progressbar
@import "./systems/progressbar";

//Buttons
@import "./systems/_buttons.scss";

//Switches
@import "./systems/switches";

//Checkbox
@import "./systems/checkbox";

//Radio
@import "./systems/radio-button";

//Border
@import "./systems/border";

//Width-Height Classes
@import "./systems/w-h-classes";

//Font Size
@import "./systems/font-size";

//Inputs
@import "./systems/inputs";

//Icons
@import "./systems/icons";

//Actions
@import "./systems/actions";

//Divider
@import "./systems/divider";

//Antd Forced Classes
@import "./antd-forced-classes";

//Views
@import "./view/tickets"; //-- Tickets
@import "./view/profile"; //-- Profile
@import "./view/wallet"; //-- Wallet
@import "./view/order-detail"; //-- Order Detail
@import "./view/create-fba"; //-- Create FBA
@import "./view/create-fbm"; //-- Create FBM
@import "./view/product-info"; //-- Product Info
@import "./view/customers"; //-- Customers
@import "./view/settings"; //-- Settings

.ant-layout {
  background: white;
}

// TABS BEGIN

:where(.css-dev-only-do-not-override-xu9wm8).ant-tabs
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0px;
}

.ant-tabs-tab {
  min-width: 140px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $slate-blue-05 !important;

  .ant-tabs-tab-btn {
    color: $slate-blue !important;
    font-weight: 100;
  }

  &.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
  }
}

.ant-tabs-ink-bar {
  background: $slate-blue !important;
}

// TABS END

// AVATAR BEGIN
.avatar-border {
  border: 3px solid $slate-blue-05 !important;
}
// AVATAR END

.amz-product-box {
  background: rgb(51, 62, 72, 0.1);
  height: 20px;
  width: 12px;
  display: inline-block;
  margin: 4px;
  border: 1px solid rgb(51, 62, 72, 0.5);
  text-align: center;
  padding-top: 4px;
  color: gray;
  cursor: pointer;
}
.education-container iframe {
  border: 0 !important;
}
