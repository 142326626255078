.font-color-oneamz-yellow {
  color: $oneamz-yellow !important;
}

.font-color-slate-blue {
  color: $slate-blue;
}

.font-color-slate-blue-05 {
  color: $slate-blue-05;
}

.font-color-slate-blue-06 {
  color: $slate-blue-06;
}

.font-color-dorian {
  color: $dorian;
}

.font-color-amz-gray {
  color: $amz-gray !important;
}

.font-color-cloud {
  color: $cloud;
}

.font-color-cloud-05 {
  color: $cloud-05;
}

.font-color-pure-white {
  color: $pure-white;
}

.font-color-onyx {
  color: $onyx;
}

.font-color-success {
  color: $success;
}

.font-color-error {
  color: $error;
}

.font-color-warning {
  color: $warning;
}

.font-color-info {
  color: $info;
}

.menu-fc-slate-blue {
  p {
    color: $slate-blue;
    font-weight: 700;
  }
}
