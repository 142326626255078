.switch-oneamz-yellow.ant-switch-checked {
  background: $oneamz-yellow !important;
}

.switch-bg {
  background: #e6e6e6 !important;
}

.switch-slate-blue.ant-switch-checked {
  background: $slate-blue !important;
}
