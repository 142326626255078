.fs-10 {
  font-size: 0.65rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-32 {
  font-size: 2rem;
}
