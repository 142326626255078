@font-face {
  font-family: "Amazon";
  src: url("../../fonts/AmazonEmber_Md.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Amazon";
  src: url("../../fonts/AmazonEmber_Bd.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Amazon";
  src: url("../../fonts/AmazonEmber_Rg.ttf") format("truetype");
  font-weight: normal;
}
