.menu-oneship {
  .menu-wrapper {
    min-height: 60px;
  }
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    flex: auto;
    padding: 0 !important;
  }
  .ant-collapse-content {
    background-color: transparent !important;
    border: none !important;
  }
  div.ant-col.css-dev-only-do-not-override-1qfezbu {
    width: 37.2px;
    text-align: center;
  }
}
