.package-box {
  background: #fff;
  padding: 7px;
  width: 240px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.product-box {
  background: rgba(255, 203, 43, 0.22);
  height: 20px;
  width: 12px;
  display: inline-block;
  margin: 4px;
  border: 1px solid #ffcb2b;
  text-align: center;
  padding-top: 4px;
  color: gray;
  cursor: pointer;
}

.package-title {
  margin-right: 0px;
  width: 240px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  display: flex;
  align-items: middle;
  justify-content: space-between;
}
