//oneamz-yellow
.bg-oneamz-yellow {
  background: $oneamz-yellow;
}

.bg-oneamz-yellow-02 {
  background: $oneamz-yellow-02;
}

.bg-oneamz-yellow-04 {
  background: $oneamz-yellow-04;
}

.bg-oneamz-yellow-06 {
  background: $oneamz-yellow-06;
}

.bg-oneamz-yellow-08 {
  background: $oneamz-yellow-08;
}

//slate-blue
.bg-slate-blue {
  background: $slate-blue;
}

.bg-slate-blue-01 {
  background: $slate-blue-01;
}

.bg-slate-blue-blur {
  background-color: $slate-blue-04;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
}

.bg-slate-blue-02 {
  background: $slate-blue-02;
}

.bg-slate-blue-04 {
  background: $slate-blue-04;
}

.bg-slate-blue-05 {
  background: $slate-blue-05;
}

.bg-slate-blue-06 {
  background: $slate-blue-06;
}

.bg-slate-blue-08 {
  background: $slate-blue-08;
}

//dorian
.bg-dorian {
  background: $dorian;
}

//cloud
.bg-cloud {
  background: $cloud;
}

//cloud
.bg-cloud-05 {
  background: $cloud-05;
}

.bg-cloud-02 {
  background: $cloud-02;
}

//pure-white
.bg-pure-white {
  background: $pure-white;
}

//onyx
.bg-onyx {
  background: $onyx;
}

//Success
.bg-success {
  background: $success;
}

.bg-success-02 {
  background: $success-02;
}

.bg-success-04 {
  background: $success-04;
}

.bg-success-06 {
  background: $success-06;
}

.bg-success-08 {
  background: $success-08;
}

//Error
.bg-error {
  background: $error;
}

.bg-error-02 {
  background: $error-02;
}

.bg-error-04 {
  background: $error-04;
}

.bg-error-06 {
  background: $error-06;
}

.bg-error-08 {
  background: $error-08;
}

//Warning
.bg-warning {
  background: $warning;
}

.bg-warning-02 {
  background: $warning-02;
}

.bg-warning-04 {
  background: $warning-04;
}

.bg-warning-06 {
  background: $warning-06;
}

.bg-warning-08 {
  background: $warning-08;
}

//Info
.bg-info {
  background: $info;
}

.bg-info-02 {
  background: $info-02;
}

.bg-info-04 {
  background: $info-04;
}

.bg-info-06 {
  background: $info-06;
}

.bg-info-08 {
  background: $info-08;
}

//Transparent
.bg-transparent {
  background: transparent;
}
