$oneamz-yellow: #ffcb2b;
$oneamz-yellow-02: rgb(255, 153, 0, 0.2);
$oneamz-yellow-04: rgb(255, 153, 0, 0.4);
$oneamz-yellow-06: rgb(255, 153, 0, 0.6);
$oneamz-yellow-08: rgb(255, 153, 0, 0.8);
$slate-blue: #333e48;
$slate-blue-01: rgba(51, 62, 72, 0.1);
$slate-blue-02: rgba(51, 62, 72, 0.2);
$slate-blue-04: rgba(51, 62, 72, 0.4);
$slate-blue-05: rgba(51, 62, 72, 0.5);
$slate-blue-06: rgba(51, 62, 72, 0.6);
$slate-blue-08: rgba(51, 62, 72, 0.8);
$dorian: #d4d4d4;
$cloud: #f4f4f4;
$cloud-05: rgb(244, 244, 244, 0.5);
$cloud-02: rgb(244, 244, 244, 0.2);
$amz-gray: #70787f;
$pure-white: #ffffff;
$onyx: #1c2023;
$success: #31d0aa;
$success-02: rgb(49, 208, 170, 0.2);
$success-04: rgb(49, 208, 170, 0.4);
$success-06: rgb(49, 208, 170, 0.6);
$success-08: rgb(49, 208, 170, 0.8);
$error: #ed4b4b;
$error-02: rgb(237, 75, 75, 0.2);
$error-04: rgb(237, 75, 75, 0.4);
$error-06: rgb(237, 75, 75, 0.6);
$error-08: rgb(237, 75, 75, 0.8);
$warning: #ed8e4b;
$warning-02: rgb(237, 75, 75, 0.2);
$warning-04: rgb(237, 75, 75, 0.4);
$warning-06: rgb(237, 75, 75, 0.6);
$warning-08: rgb(237, 75, 75, 0.8);
$info: #4b7bed;
$info-02: rgb(75, 123, 237, 0.2);
$info-04: rgb(75, 123, 237, 0.4);
$info-06: rgb(75, 123, 237, 0.6);
$info-08: rgb(75, 123, 237, 0.8);

.bg-red {
  background-color: red;
}
.bg-orange {
  background-color: orange;
}
.bg-purple {
  background-color: purple;
}
.bg-blue {
  background-color: blue;
}
.bg-lime {
  background-color: lime;
}
.bg-green {
  background-color: green;
}
.bg-default {
  background-color: grey;
}
