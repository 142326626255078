.p-8 {
  padding: 0.5rem;
}

.p-16 {
  padding: 1rem;
}

.p-24 {
  padding: 1.5rem;
}

.p-32 {
  padding: 2rem;
}

//Padding Right
.pr-8 {
  padding-right: 0.5rem;
}

.pr-12 {
  padding-right: 0.75rem;
}

.pr-16 {
  padding-right: 1rem;
}

.pr-24 {
  padding-right: 1.5rem;
}

.pr-32 {
  padding-right: 2rem;
}

//Padding Left
.pl-8 {
  padding-left: 0.5rem;
}

.pl-12 {
  padding-left: 0.75rem;
}

.pl-16 {
  padding-left: 1rem;
}

.pl-24 {
  padding-left: 1.5rem;
}

.pl-32 {
  padding-left: 2rem;
}

// Padding Top
.pt-4 {
  padding-top: 0.25rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pt-12 {
  padding-top: 0.75rem;
}

.pt-16 {
  padding-top: 1rem;
}

.pt-24 {
  padding-top: 1.5rem;
}

.pt-32 {
  padding-top: 2rem;
}

// Padding Bottom
.pb-8 {
  padding-bottom: 0.5rem;
}

.pb-12 {
  padding-bottom: 0.75rem;
}

.pb-16 {
  padding-bottom: 1rem;
}

.pb-24 {
  padding-bottom: 1.5rem;
}

.pb-32 {
  padding-bottom: 2rem;
}

.pb-48 {
  padding-bottom: 3rem;
}

// Sıfır
.p-0 {
  padding: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-4 {
  padding: 0.25rem 0;
}

.py-16 {
  padding: 1rem 0;
}
